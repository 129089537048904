import React from 'react';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Cards from '../../Components/Cards';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CardsInfo from '../../Components/CardsInfo';
import Str from '../../Assets/sj.jpg';
// import Slide from '../../Assets/ajinkyatara.jpg';
function Home() {


   return (
      <>

         <Container fluid className='bg-light '>
            <Row className='p-3  d-flex align-items-start justify-content-start  text-dark'>
               <Col className='p-3 ' sm={6}>
                  <p className='fs-1 fw-bold'>Satara Business
                  </p>

                  <p className='textJustify'> Dear Hotel and Restaurant owners, Consultants, professionals, Coaching class owners, Doctors, and all other Shop owners. You need more visibility to all your local potential customers as well as visitors to Satara. Display your business information or about you on our website and stay connected across all forums to create more business opportunities for you. Reach a broader audience without traditional advertising costs.
                     We will display your business address, map, and contact details, state operating hours and days of the week. What we need from you are images and videos about your business with product and service information.
                     All this at a very nominal costs</p>
                  <p className='textJustify'>Our website intends to display your business identity for the benefit of all categories of local customers. We will help you to capture potential clients through online inquiries. Reach a broader audience without traditional advertising costs. Impress potential clients with a visually appealing portfolio. Increase your visibility. Attract local customers searching for products or services. Contact : 8983972605</p>

               </Col>
               <Col>
                  <img src={Str} alt='img1' className='img-fluid rounded' />
               </Col>

            </Row>

         </Container>



         <CardsInfo />
         <Container fluid>
            <Row>
               <Col lg={6} >

                  <Form className='m-3  rounded-4' id="contact-form">


                     <Row className='m-3 p-3 '>
                        <h2 className='m-3'>Get in Touch with Us</h2>

                        <Col lg={12} >
                           <Form.Control type="text" placeholder="Enter Name" className='p-2 m-2' name="from_name" id="from_name" required />
                        </Col>
                        <Col lg={12} >
                           <Form.Control type="email" placeholder="Enter email" className='p-2 m-2' name="user_email" required />
                        </Col>
                        <Col lg={12}>
                           <Form.Control type="text" placeholder="Phone" className='p-2 m-2' name="contact_number" required />
                        </Col>
                        <Col lg={12}>
                           <Form.Control as="textarea" rows={3} className='p-2 m-2' name="message" id="message" required />
                        </Col>
                        <Col lg={12} >

                           <Button variant="success" type="submit" className='p-2 m-2 mb-4 fw-bold'>
                              <Form.Control type="submit" value="Send" />
                           </Button>


                        </Col>

                     </Row>
                  </Form>

               </Col>
               <Col lg={6} className="p-3 text-center">
               <div class="mapouter m-3"><div class="gmap_canvas"><iframe class="gmap_iframe" width="100%" height='500' frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.google.com/maps/embed/v1/place?q=franctel+network+sai+tower+powai+naka+satara&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe><a href="https://embed-googlemap.com"></a></div><style></style></div>

               </Col>
            </Row>
            <Row className='m-3 p-3'>

               <Col lg={4} >

                  <Row className=' m-1 border p-2 shadow'>
                     <Col sm={2} className='p-2 text-sm-end text-center'><i className="fa-solid fa-envelope fs-1 text-theme"></i> </Col>
                     <Col ><div className='text-sm-start text-center text-grey'>help@satarabusiness.com</div></Col>
                  </Row>
               </Col>
               <Col lg={4}>
                  <Row className='m-1 border p-2 shadow'>
                     <Col sm={2} className='p-2 text-sm-end text-center'><i className="fa-solid fa-location-dot fs-1 text-theme"></i> </Col>
                     <Col ><div className='text-sm-start text-center text-grey'>Satara, Maharashtra 415002</div></Col>
                  </Row>
               </Col>
               <Col lg={4}>
                  <Row className='m-1 border p-2 shadow'>
                     <Col sm={2} className='p-2 text-sm-end text-center'><i className="fa-solid fa-phone fs-1 text-theme"></i></Col>
                     <Col ><div className='text-sm-start text-center text-grey'>+91 8983972605</div></Col>
                  </Row>
               </Col>

            </Row>
         </Container>
      </>
   )

}

export default Home;