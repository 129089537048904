import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../../Assets/logo11.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Header() {

    return (

        <>

            <Navbar expand="lg" bg="light" variant="light" className='sticky-top  p-0' >
                <Container fluid className='px-5 py-0'>
                    <Navbar.Brand href="/" className='fw-bold fs-3 '>
                        <img
                            src={logo}
                            width="100"
                            height="100"
                            className="d-inline-block align-top rounded   "
                            alt="logo"
                        />

                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className='transparent-header'>

                            <Nav.Link as={Link} to="/" className='text-uppercase text-center mx-2 fw-bold text-theme'>Home</Nav.Link>

                            <Nav.Link as={Link} to="/About" className='text-uppercase text-center mx-2 fw-bold'>About Us</Nav.Link>
                            <NavDropdown title="Categories" id="basic-nav-dropdown" className='text-uppercase text-center mx-2 fw-bold'>
                                <NavDropdown.Item as={Link} to="/hotel">Hotel</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/health">Health</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/food">Food </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/travel">Travel </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/electronics">Electronics </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/sports">Sports </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/furniture">Furniture </NavDropdown.Item>
                                {/* <NavDropdown.Item as={Link} to="/stationary">Stationary </NavDropdown.Item> */}
                            </NavDropdown>
                            <Nav.Link as={Link} to="/Contact" className='text-uppercase text-center mx-2 fw-bold'>Contact</Nav.Link>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )

}

export default Header;