import { Container } from 'react-bootstrap';
// import { Button } from 'react-bootstrap';
import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import g1 from '../../Assets/satara.jpg';
import g2 from '../../Assets/satara.jpg';
import g3 from '../../Assets/satara.jpg';
import g4 from '../../Assets/satara.jpg';
import g5 from '../../Assets/satara.jpg';
import g6 from '../../Assets/satara.jpg';
import g7 from '../../Assets/satara.jpg';
import g8 from '../../Assets/satara.jpg';
import g9 from '../../Assets/satara.jpg';
import g10 from '../../Assets/satara.jpg';

function Cards(props) {
    const [galleryData, setGalleryData] = useState();
    useEffect(() => {
        setGalleryData(props.galleryData);
    }, [props.galleryData]);

    var img = [{ imgSrc: g1 }, { imgSrc: g2 }, { imgSrc: g3 }, { imgSrc: g4 }, { imgSrc: g5 }, { imgSrc: g6 }, { imgSrc: g7 }, { imgSrc: g8 }, { imgSrc: g9 }, { imgSrc: g10 }, { imgSrc: g2 }, { imgSrc: g3 }]
    return (
        <>
            <Container fluid>
                <Row className=''>
                    <Col className=''>
                        <h1 className='text-dark m-2 fw-bold text-center'>
                            Categories</h1>
                      
                    </Col>
                </Row>
                <Row className='p-3'>
                    {img.map((images,  index) => {
                        return (<Col key={index} sm={6} lg={4} className='p-3'>
                            <Card>
                                <Card.Img variant="top" src={images.imgSrc} className='img-fluid rounded zoom-img' />
                            </Card>
                        </Col>)
                    })}
                    <hr />
                    {
                        galleryData && galleryData.map((elem, ind) => {
                            return (
                                <Col key={ind} sm={6} lg={4} className='p-3'>
                                    <Card>
                                        <Card.Img variant="top" src={elem.image} className='img-fluid rounded zoom-img' />

                                    </Card>

                                </Col>)
                        })}

                </Row>
            </Container>

        </>
    )

}

export default Cards;


