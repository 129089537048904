import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import React from 'react';
import Food1 from '../../Assets/travel.jpg';
import Food2 from '../../Assets/travel.jpg';
import Food3 from '../../Assets/travel.jpg';

function Travel() {
  return (
    <>
      <Container fluid className=''>

        <Row className='headingimg p-4'>
          <Col className='m-4'>
            <h1 className='text-white m-5 px-2 fw-bold text-start'>Travel</h1>
            <div className='text-white m-5 fw-bold text-start'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Travel</a></div>
          </Col>
        </Row>
        <Row>
        <h1 className='text-dark p-3 fw-bold text-center'>Satara Travel</h1>

          <Col className='p-3  textJustify'>

            <div className="card " >
              <div className="row g-0">
                <div className="col-md-4 p-0">
                  <img src={Food1} className="img-fluid rounded" alt="..." />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title">ABC Travel</h5>
                    {/* <p className="card-text">Satara special Kandi Pedha is a very famous dish.</p> */}
                    {/* <a href="./KandiPedhe"  className="btn btn-primary text-center">View More</a> */}

                  </div>
                </div>
              </div>
            </div>

          </Col>

          <Col className='p-3  textJustify'>

            <div className="card" >
              <div className="row g-0">
                <div className="col-md-4">
                  <img src={Food2} className="img-fluid rounded" alt="..." />

                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title">DEF Travel</h5>
                    {/* <p className="card-text">Satara special Vada Pav is a very famous dish.</p> */}
                    {/* <a href="./VadaPav" className="btn btn-primary text-center">View More</a> */}

                  </div>
                </div>
              </div>
            </div>

          </Col>
          <Col className='p-3  textJustify'>

            <div className="card" >
              <div className="row g-0">
                <div className="col-md-4">
                  <img src={Food3} className="img-fluid rounded" alt="..." />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title">GHI Travel</h5>
                    {/* <p className="card-text">Satara special Dabeli is a very famous dish.</p> */}
                    {/* <a href="./Dabeli" className="btn btn-primary text-center">View More</a> */}

                  </div>
                </div>
              </div>
            </div>

          </Col>
        </Row>
        

      </ Container >



    </>
  )

}

export default Travel;