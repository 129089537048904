import { Container } from 'react-bootstrap';
import React from "react";
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
// import Card from 'react-bootstrap/Card';
import g1 from '../../Assets/health.jpeg';
import g2 from '../../Assets/food.jpg';
import g3 from '../../Assets/travel.jpg';
import g4 from '../../Assets/electronics.jpg';
import g5 from '../../Assets/sports1.jpg';
import g6 from '../../Assets/hotel.webp';


function CardsInfo() {


    return (
        <>
            <Container fluid>
                <Row className=''>
                    <Col className=''>
                        <h1 className='text-dark m-2 fw-bold text-center'>
                            Categories</h1>

                    </Col>
                </Row>
                <Row className='p-3'>
                <Col  lg={4} className='p-3'>
                        <div className="card">
                            <img src={g6} className='img-fluid img-circle' alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Hotels</h5>
                                <a href="./Hotel" className="btn btn-primary">View More</a>
                            </div>
                        </div>
                    </Col>
                    <Col  lg={4} className='p-3'>
                        <div className="card">
                            <img src={g2} className='img-fluid img-circle' alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Food</h5>
                                <a href="./Food" className="btn btn-primary">View More</a>
                            </div>
                        </div>
                    </Col>
                    <Col  lg={4} className='p-3'>
                        <div className="card">
                            <img src={g3} className='img-fluid img-circle' alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Travel</h5>
                                <a href="./Travel" className="btn btn-primary">View More</a>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className='p-3'>
                    <Col  lg={4} className='p-3'>
                        <div className="card">
                            <img src={g4} className='img-fluid img-circle' alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Electronics</h5>
                                <a href="./Electronics" className="btn btn-primary">View More</a>
                            </div>
                        </div>
                    </Col>
                    <Col  lg={4} className='p-3'>
                        <div className="card">
                            <img src={g5} className='img-fluid img-circle' alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Sports</h5>
                                <a href="./Sports" className="btn btn-primary">View More</a>
                            </div>
                        </div>
                    </Col>
                
                    <Col  lg={4} className='p-3'>
                        <div className="card">
                            <img src={g1} className='img-fluid img-circle' alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Health</h5>
                                <a href="./Health" className="btn btn-primary">View More</a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

        </>
    )

}

export default CardsInfo;


