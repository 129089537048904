import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';

function About() {
   
    return (

        <>
            <Container fluid className=''>

                <Row className='headingimg p-4'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-start'>About Us</h1>
                        <div className='text-white m-5 fw-bold text-start'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>About Us</a></div>
                    </Col>

                </Row>
                <Row>
                    
                    <Col className='p-3  textJustify'>
                        <p >
                        Satara Business.com is developed by Franctel Network Pvt Ltd. The company director Stanley
Saldanha is a former Maharashtra Ranji trophy cricketer with 25 years of corporate experience. Also
three years 2007-2010 as the Manager of Game Development at the Board of Control for Cricket in
India ( BCCI). Franctel has started a local office in Satara with a vision to provide job opportunities to
local youngsters. Working in the same direction we decided to create an open platform for all types of
businesses to display their products and services on a common website satarabusiness.com. The
local customers and users of services can have access to such businesses and service providers
using this website. Even the customers outside Satara will come to know the availability of products
and services which they would like to purchase or use during their visit to Satara</p>
 

                      
                    </Col>


                </Row>
             
            </ Container >



        </>
    )

}

export default About;