import React from 'react';

import { Container } from 'react-bootstrap';

import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
function Footer() {

  return (

    <>

      <footer className='bg-light'>
        <Container fluid >
        

          <Row>
            <Col >
              <h5 className='text-center p-2 text-dark'>Follow Us</h5>
              <div className='d-flex justify-content-between justify-content-sm-center p-2'>
                <a href="#" target="_blank"><i className="fa-brands fa-youtube fs-1 mx-2 text-danger rotate"></i></a>
                <a href="#" target="_blank"><i className="fa-brands fa-facebook fs-1 mx-2 text-info rotate"></i></a>
                <a href="#" target="_blank"><i className="fa-brands fa-instagram fs-1 mx-2 text-danger rotate"></i></a>

                <a href="#" target="_blank"><i className="fa-brands fa-linkedin fs-1 mx-2 text-primary rotate"></i></a>
              </div>
            </Col>
          </Row>
          <Row className='p-3 text-center text-dark'>
          <Col>
          <Link to="/" className='text-dark text-decoration-none p-3'>Home</Link>
          <Link to="/About" className='text-dark text-decoration-none p-3'>About</Link>
          <Link to="/Contact" className='text-dark text-decoration-none p-3'>Contact</Link>
          <Link to="/" className=' text-decoration-none text-dark p-3'>Privacy Policy</Link>
          </Col>
          </Row>

          <Row>
            <Col className='text-center p-2 text-dark'>
              <span >@{new Date().getFullYear()} www.satarabusiness.com
              </span>
            </Col>
          </Row>
        </Container>
      </footer>

    </>
  )

}

export default Footer;