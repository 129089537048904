import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import React from 'react';
import DrImg from '../../Assets/re2.png';

function Health() {
    const imgData = [
        { name: "Dr. ABC", degree: "MBBS", speciality: "Pediatrics, Neonatology", src: DrImg, }, { name: "Dr. DEF", degree: "MBBS,MD", speciality: "Pediatrics, Neonatology", src: DrImg, }, { name: "Dr. GHI", degree: "MBBS, DCH, DNB, MRCPCH", speciality: "Pediatrics, Neonatology", src: DrImg, }, { name: "Dr. JKL", degree: "MBBS, DCH, MRCPCH", speciality: "Pediatrics, Neonatology", src: DrImg, }
        , { name: "Dr.MNO ", degree: "MBBS,MRCPCH", speciality: "Pediatrics, Neonatology", src: DrImg, }, { name: "Dr. PQR", degree: "MBBS,MD", speciality: "Pediatrics, Neonatology", src: DrImg, }]
    return (

        <>
            <Container fluid className=''>

                <Row className='headingimg p-4'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-start'>Health</h1>
                        <div className='text-white m-5 fw-bold text-start'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Health</a></div>
                    </Col>

                </Row>
                <Row>

                    <Col>
                        <h1 className='text-center p-3'>Doctors in Satara</h1>
                    </Col>


                </Row>
                <Row className='p-3'>
                    {imgData.map((item, index) => {
                        return (<Col key={index} sm={6} lg={3} className='p-3'>

                            <div className="card p-4">
                                <img src={item.src} className='img-fluid img-circle' alt="..." />
                                <div className="card-body text-center">
                                    <h5 className="card-title text-center">{item.name}</h5>
                                    <h6>{item.degree}</h6>

                                    <p>{item.speciality}</p>

                                    <a href="#" className="btn btn-primary text-center">View More</a>
                                </div>
                            </div>
                        </Col>)
                    })}


                </Row>

            </ Container >



        </>
    )

}

export default Health;