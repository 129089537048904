import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import React from 'react';
import Furniture3 from '../../Assets/furniture1.jpg';
import Furniture1 from '../../Assets/furniture1.jpg';
import Furniture2 from '../../Assets/furniture1.jpg';

function Furniture() {
  const imgData = [
    { name: "raj wood deco", Type: "furniture", MobileNO: "8445277588", src: Furniture3, }, { name: "devikrupa plywood", Type: "furniture", MobileNO: "9890836346", src: Furniture3, }, { name: "darbar furniture", Type: "furniture", MobileNO: "7972953249", src: Furniture3, }, { name: "shri sai furniture", Type: "furniture", MobileNO: "7058937910", src: Furniture3, }, { name: "satara furniture", Type: "furniture", MobileNO: "9923563442", src: Furniture3, }, { name: "new maharashtra furnishing", Type: "furniture", MobileNO: "8956008763", src: Furniture3, } ]
  return (
    <>
      <Container fluid className=''>

        <Row className='headingimg p-4'>
          <Col className='m-4'>
            <h1 className='text-white m-5 px-2 fw-bold text-start'>Furniture</h1>
            <div className='text-white m-5 fw-bold text-start'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Furniture</a></div>
          </Col>
        </Row>
        <Row className='p-3'>
          {imgData.map((item, index) => {
            return (<Col key={index} sm={6} lg={3} className='p-3'>

              <div className="card p-4">
                <img src={item.src} className='img-fluid img-circle' alt="..." />
                <div className="card-body text-center">
                  <h5 className="card-title text-center">{item.name}</h5>
                  <h6>{item.Type}</h6>

                  <p>{item.MobileNO}</p>

                  <a href="#" className="btn btn-primary text-center">View More</a>
                </div>
              </div>
            </Col>)
          })}


        </Row>

        

      </ Container >



    </>
  )

}

export default Furniture;