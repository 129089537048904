import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import React from 'react';
import s1 from '../../Assets/maharaja/s4.jpg';
import s2 from '../../Assets/maharaja/s2.jpg';
import s3 from '../../Assets/maharaja/s3.jpg';
import s4 from '../../Assets/maharaja/s4.jpg';
import s5 from '../../Assets/maharaja/s5.jpg';
import s6 from '../../Assets/maharaja/s6.jpg';
import s7 from '../../Assets/maharaja/s7.jpg';
import s8 from '../../Assets/maharaja/s8.jpg';
import s9 from '../../Assets/maharaja/s9.jpg';



function Sayaji() {
    return (
        <>
            <Container fluid className=''>

                <Row className='headingimg p-4'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-start'>Hotel Sayaji</h1>
                        <div className='text-white m-5 fw-bold text-start'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Hotel Sayaji</a></div>
                    </Col>
                </Row>
                <Row>
                    <h1 className='text-dark p-3 fw-bold text-center'>Hotel Effotel by Sayaji</h1>

                    <Col className='p-3  textJustify'>

                        <Row className=" p-3">
                            <Col className=" p-0" lg={6}>
                            <img src={s1} className="d-block w-100 rounded" alt="..." />

                                               </Col>
                            <Col className="" lg={6}>
                                <h5 className="text-center"> Hotel Effotel by Sayaji, Satara</h5>
                                <p className="">Hotel Sayaji is a business cum leisure hotel most centrally located in the heart of the city, at Powai Naka close to Shivaji Circle, just 6.5 KM drive from Railway Station, 1.5 KM walk from Interstate Bus Terminal.</p>
                                <p> The Royal tower styled “Hotel Sayaji” set amidst landscaped lush green in the city centre offers a blend of Indian and traditional hospitality “अतिथि देवो भव:” in an atmosphere of timeless elegance beyond comfortable and specious 44 Rooms equipped with all unique combination of essential and modern facilities that make your stay memorable & wow </p>
                                <p> A choice of accommodation with business facilities, well equipped Conference Hall, Multi cuisine Restaurant, Green lush lawn and efficient service make the Hotel best “Icon of Hospitality in Satara for the our business and leisure Travelers .</p>
                                <p> Our Director Mr. Ravindra Baburao Chavan, a builder by profession and Mr. Mahendra Baburao Chavan, an Architect by profession has put together their expertise and experience with an exposure of international standard to make this beautiful, attractive & artistic property one of the best in Satara city</p>


                            </Col>
                        </Row>

                    </Col>


                </Row>
                <h3 className='text-center p-3'>Gallery</h3>

                <Row className='p-3'>
                <Col>
                <img src={s2} className="d-block w-100 rounded" alt="..." />

                </Col>
                <Col>
                <img src={s3} className="d-block w-100 rounded" alt="..." />

                </Col> <Col>
                <img src={s4} className="d-block w-100 rounded" alt="..." />

                </Col> <Col>
                <img src={s5} className="d-block w-100 rounded" alt="..." />

                </Col>
  
                </Row>
                <Row className='p-3'>
                <Col>
                <img src={s6} className="d-block w-100 rounded" alt="..." />

                </Col>
                <Col>
                <img src={s7} className="d-block w-100 rounded" alt="..." />

                </Col> <Col>
                <img src={s8} className="d-block w-100 rounded" alt="..." />

                </Col> <Col>
                <img src={s9} className="d-block w-100 rounded" alt="..." />

                </Col>
  
                </Row>
                <Row className='p-3'>
                <Col>
                    <h3 className='text-center'>Contact</h3>
                    <p>ADDRESS :  174,SHIVAJI CIRCLE,POWAI NAKA,SATARA.</p>
                    <p>PHONE NO : 969913793 / 7499272363</p>
                    <p>EMAIL ID : am.fnb@effotelsatara.com</p>


                                                                  

                                         



                </Col>
                </Row>
            </ Container >



        </>
    )

}

export default Sayaji;