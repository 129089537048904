import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import React from 'react';
import FoodImg from '../../Assets/food.jpg';
import FoodImg1 from '../../Assets/bakery.jpg';

function Food() {
  const imgData = [
    { name: "shri ganesh rajpurohit", Type: "Bakery", MobileNO: "9166015544", src: FoodImg1, },
    { name: "shri krushna rajpurohit", Type: "Bakery", MobileNO: "8085547115", src: FoodImg1, },
    { name: "vijay keshar hause", Type: "Bakery", MobileNO: "9422038944", src: FoodImg1, },
    { name: "shahi biryani", Type: "hotel", MobileNO: "9096241724", src: FoodImg, },
    { name: "omkar resto", Type: "hotel", MobileNO: "7248904974", src: FoodImg, },
    { name: "hotel vijaylaksmi ", Type: "hotel", MobileNO: "9822852769", src: FoodImg, },
    { name: "hotel apple", Type: "hotel", MobileNO: "7741065615", src: FoodImg, },
    { name: "shri mahalakshmi sweet mart ", Type: "Bakery", MobileNO: "8529494335", src: FoodImg1, },
    { name: "hotel kasturi corner", Type: "hotel", MobileNO: "9049575129", src: FoodImg, },
    { name: "panchali hotel", Type: "hotel", MobileNO: "9822016424", src: FoodImg, },
    { name: "vithumai resto", Type: "hotel", MobileNO: "7776070676", src: FoodImg, },
    { name: "alankar resto hotel", Type: "hotel", MobileNO: "9807577777", src: FoodImg, },
    { name: "hotel lakeview", Type: "Bakery", MobileNO: "9822546664", src: FoodImg1, },
    { name: "shaurya restaurant", Type: "Bakery", MobileNO: "9768577712", src: FoodImg1, },
    { name: "sairam resto", Type: "Bakery", MobileNO: "7030052494", src: FoodImg1, },
    { name: "siddheshwar resto", Type: "Bakery", MobileNO: "9307135988", src: FoodImg1, },
    { name: "krushna chinies ", Type: "Bakery", MobileNO: "9029487263", src: FoodImg, },
    { name: "krushna snacks center", Type: "Bakery", MobileNO: "8052856087", src: FoodImg, },
    { name: "hotel yogiraj", Type: "Bakery", MobileNO: "9923746211", src: FoodImg, },
    { name: "shahi pedhewale", Type: "Bakery", MobileNO: "8976761430", src: FoodImg, },
    { name: "panihari hotel", Type: "Bakery", MobileNO: "9772656983", src: FoodImg, },
    { name: "palash hotel", Type: "Bakery", MobileNO: "9637422571", src: FoodImg, },
    { name: "falcon hotel", Type: "Bakery", MobileNO: "8080721276", src: FoodImg, },
    { name: "vitthal kamat hotel", Type: "Bakery", MobileNO: "9850818186", src: FoodImg, },
     { name: "hotel manas", Type: "Bakery", MobileNO: "9637422571", src: FoodImg, },
      { name: "navghane bandhu", Type: "Bakery", MobileNO: "8999539100", src: FoodImg, },
      { name: "s.k.snacks", Type: "Bakery", MobileNO: "9767997238", src: FoodImg, },
       { name: "hotel dakkhan family", Type: "Bakery", MobileNO: "8626030043", src: FoodImg, },
        { name: "country in lodging", Type: "Bakery", MobileNO: "9923746217", src: FoodImg, }, 
        { name: "hotel sanjwara", Type: "Bakery", MobileNO: "9822370871", src: FoodImg, }, 
        { name: "ashwini kitchen hotel", Type: "Bakery", MobileNO: "8308663710", src: FoodImg, }, 
        { name: "tiranga biryani", Type: "Bakery", MobileNO: "9764286161", src: FoodImg, }, 
        { name: "hotel vijay ", Type: "Bakery", MobileNO: "7420841315", src: FoodImg, }, 
        { name: "shrawani fruits and snacks", Type: "Bakery", MobileNO: "7420841315", src: FoodImg, }, 
        { name: "rajwada famus lassi", Type: "Bakery", MobileNO: "8530755595", src: FoodImg, }, 
        { name: "big daddys chinies resto", Type: "Bakery", MobileNO: "7720907468", src: FoodImg, }, 
        { name: "hotel mahavir", Type: "Bakery", MobileNO: "8796766303", src: FoodImg, }, 
        { name: "hotel jijau", Type: "Bakery", MobileNO: "7758861330", src: FoodImg, }, 
        { name: "raj lodging", Type: "Bakery", MobileNO: "8806859191", src: FoodImg, },
         { name: "naaz hotel", Type: "Bakery", MobileNO: "8282827786", src: FoodImg, },
         { name: "purnbramha hotel", Type: "Bakery", MobileNO: "7020963512", src: FoodImg, }, 
         { name: "gudluck bakery", Type: "Bakery", MobileNO: "7038553716", src: FoodImg, }, 
         { name: "satara fruits", Type: "Bakery", MobileNO: "9767499355", src: FoodImg, },
          { name: "shri satish shengdana", Type: "Bakery", MobileNO: "9822546604", src: FoodImg, },
          { name: "vedant milk and milk product", Type: "Bakery", MobileNO: "7400137429", src: FoodImg, }, 
          { name: "maharashtra chicken center", Type: "Bakery", MobileNO: "9922483706", src: FoodImg, }, 
          { name: "satara mutton shop", Type: "Bakery", MobileNO: "9860873091", src: FoodImg, },
           { name: "hotel mahindra", Type: "Bakery", MobileNO: "7841852680", src: FoodImg, },
           { name: "hotel shrikrushn", Type: "Bakery", MobileNO: "9765499009", src: FoodImg, }, 
           { name: "hotel pranjali", Type: "Bakery", MobileNO: "9422401094", src: FoodImg, }, 
           { name: "gurukrupa hotel", Type: "Bakery", MobileNO: "9975131817", src: FoodImg, },
            { name: "satari kandi pedhe", Type: "Bakery", MobileNO: "9046006641", src: FoodImg, },
            { name: "sunrise chinies", Type: "Bakery", MobileNO: "9545445566", src: FoodImg, }, 
            { name: "vrundavan lodging", Type: "Bakery", MobileNO: "9373813258", src: FoodImg, }, 
            { name: "hotel shivtej", Type: "Bakery", MobileNO: "9881348241", src: FoodImg, },
             { name: "hotel nivant", Type: "Bakery", MobileNO: "9422332297", src: FoodImg, },
             { name: "hotel amantran", Type: "Bakery", MobileNO: "9921900095", src: FoodImg, }, 
             { name: "hotel jambhale bandhu", Type: "Bakery", MobileNO: "7798846006", src: FoodImg, }, 
             { name: "hotel pari", Type: "Bakery", MobileNO: "7498942366", src: FoodImg, },
              { name: "redeye café ", Type: "Bakery", MobileNO: "9022216237", src: FoodImg, },
              { name: "chairman lassi ", Type: "Bakery", MobileNO: "8055779886", src: FoodImg, },
              { name: "all india bhel", Type: "Bakery", MobileNO: "9881352791", src: FoodImg, },
              { name: "testy bite", Type: "Bakery", MobileNO: "9922622617", src: FoodImg, },
              { name: "royal biryani  ", Type: "Hotel", MobileNO: "8637700070", src: FoodImg, },
              { name: "garwa biryani", Type: "Hotel", MobileNO: "8085162424", src: FoodImg, },
              { name: "café bife ", Type: "Hotel", MobileNO: "9011741473", src: FoodImg, },
              { name: "hotel marbo", Type: "Hotel", MobileNO: "9766786718", src: FoodImg, },
              { name: "hot pizza", Type: "Hotel", MobileNO: "9850555890", src: FoodImg, },
              { name: "Hotel Palvi Family Resto ", Type: "Hotel", MobileNO: "7722009006", src: FoodImg, },
              { name: "Hotel Shalimar", Type: "Hotel", MobileNO: "9850077106", src: FoodImg, },
              { name: "Rajlakshmi Snaks Center", Type: "Hotel", MobileNO: "8793857194", src: FoodImg, },
              { name: "Ansh-Arnav Chainese", Type: "Hotel", MobileNO: "7875094156", src: FoodImg, },
              { name: "Ayesh Chicken Centar", Type: "Hotel", MobileNO: "9309777497", src: FoodImg, },
              { name: "Hotel Farmaish Corner", Type: "Hotel", MobileNO: "9374116007", src: FoodImg, },
              { name: "Sai Sharaddha Sweets ", Type: "Bakery", MobileNO: "735008880", src: FoodImg, },
              { name: "Royal Pragati Hotel", Type: "Hotel", MobileNO: "8329901557", src: FoodImg, },
              { name: "Nitiraj Bhel Center", Type: "Hotel", MobileNO: "7507873184", src: FoodImg, },
              { name: "Hotel Sahayadri vally", Type: "Hotel", MobileNO: "9834587259/9175732921", src: FoodImg, },
              { name: "Shri Bakers And Sweet", Type: "Bakery", MobileNO: "7350502357", src: FoodImg, },
              { name: "Hotel Jagdamb", Type: "Hotel", MobileNO: "8126099243", src: FoodImg, },
              { name: "Swaraj New Top Bekars", Type: "Bakery", MobileNO: "7499864032", src: FoodImg, },
              { name: "Rajmudra Bhojnalay", Type: "Hotel", MobileNO: "7276079897", src: FoodImg, },
              { name: "Darbar Chainese Café", Type: "Hotel", MobileNO: "9923343737", src: FoodImg, },
              { name: "Bagvav Biiryani And Ketras", Type: "Hotel", MobileNO: "99503154884/800786005", src: FoodImg, },
              { name: "Southy Idli", Type: "Hotel", MobileNO: "7420091515", src: FoodImg, },
              { name: "Hotel Avdooth ", Type: "Hotel", MobileNO: "720091515", src: FoodImg, },
              { name: "Hot Chips", Type: "Hotel", MobileNO: "7028408366", src: FoodImg, },
              { name: "Patil Vadewale", Type: "Hotel", MobileNO: "775794722", src: FoodImg, },
              { name: "Icecream World parlour", Type: "Bakery", MobileNO: "9763626226", src: FoodImg, },
              { name: "MOONLIGHT CHAINESE Resto", Type: "Hotel", MobileNO: "9657260081", src: FoodImg, },
              { name: "Cake Express", Type: "Bakery", MobileNO: "7499367636", src: FoodImg, },
              { name: "Sadik Bekars", Type: "Bakery", MobileNO: "9284200664", src: FoodImg, },
              { name: "Kings Hotel", Type: "Hotel", MobileNO: "8808578970", src: FoodImg, },
              { name: "Balaji Dryfruits", Type: "Hotel", MobileNO: "8788985516", src: FoodImg, },
              { name: "Hotel Shahi", Type: "Hotel", MobileNO: "9881075558", src: FoodImg, },
              { name: "Bharat Bekari", Type: "Bakery", MobileNO: "7709088004/9689522072", src: FoodImg, },
              { name: "Bharkadevi Icecream Center", Type: "Bakery", MobileNO: "9561882673", src: FoodImg, },
              { name: "Shriram Snaks Center", Type: "Hotel", MobileNO: "9967765628", src: FoodImg, },
              { name: "Raje Sweets And Bekars", Type: "Bakery", MobileNO: "8999940758", src: FoodImg, },
              { name: "Aayush Bekari", Type: "Bakery", MobileNO: "9552933767", src: FoodImg, },
              { name: "Hotel SS Chainese ", Type: "Hotel", MobileNO: "7666304063", src: FoodImg, },
              { name: "Javek Chicken", Type: "Hotel", MobileNO: "99060313784", src: FoodImg, },
              { name: "NB NadBrahm Idli", Type: "Hotel", MobileNO: "9172739294", src: FoodImg, },
              { name: "Satara Foods Mirchi Ani Saoudyache Vyapari ", Type: "Hotel", MobileNO: "8446845950", src: FoodImg, },
              { name: "Amantran Family Resto", Type: "Hotel", MobileNO: "8668210429", src: FoodImg, },
              { name: "Latkar Bandu Pedewale", Type: "Bakery", MobileNO: "9172462157", src: FoodImg, },
              { name: "Janaimala Bekar", Type: "Bakery", MobileNO: "7028389101", src: FoodImg, },
              { name: "Maddy Chainese", Type: "Hotel", MobileNO: "8698500007", src: FoodImg, },
              { name: "Morya Bhaji Kendra", Type: "Hotel", MobileNO: "9766657524", src: FoodImg, },
              { name: "Manas Bhojnalay", Type: "Hotel", MobileNO: "9673006699", src: FoodImg, },
              { name: "Hotel Baithak", Type: "Hotel", MobileNO: "98960893323", src: FoodImg, },
              { name: "Hotel Mauli", Type: "Hotel", MobileNO: "9223945053", src: FoodImg, },
              { name: "Pragti Chainese", Type: "Hotel", MobileNO: "9960295629", src: FoodImg, },
              { name: "Shri Siddivinayak hotel", Type: "Hotel", MobileNO: "7218528945", src: FoodImg, },
              { name: "Kasba Biryani", Type: "Bakery", MobileNO: "91567505091", src: FoodImg, },
              { name: "Tarang fish Outlate", Type: "Hotel", MobileNO: "7942326986", src: FoodImg, },
              { name: "All India Bhel", Type: "Bakery", MobileNO: "9822601154", src: FoodImg, },
              { name: "Vyankateswar Snaks Center", Type: "Hotel", MobileNO: "9021195537", src: FoodImg, },
              { name: "Adarsh Bekars", Type: "Bakery", MobileNO: "9665706703", src: FoodImg, },
              { name: "Garva Biryani", Type: "Hotel", MobileNO: "8055162424", src: FoodImg, },
              { name: "Bhaunchi Misal", Type: "Hotel", MobileNO: "8308812770", src: FoodImg, },
              { name: "Prabhakar Raut Mithaiwale", Type: "Bakery", MobileNO: "9822021048", src: FoodImg, },
              { name: "Raut Hotel Suryaprabha", Type: "Hotel", MobileNO: "9850040103", src: FoodImg, },
              { name: "Latkar Bandhu Pedhewale", Type: "Bakery", MobileNO: "9767857447", src: FoodImg, },
              { name: "Shri Krishna Sweet ", Type: "Bakery", MobileNO: "9049086414", src: FoodImg, },
              { name: "Hotel ELITE", Type: "Hotel", MobileNO: "917500750", src: FoodImg, },
              { name: "Aashok Modi Kandipedewale", Type: "Bakery", MobileNO: "9822116116/7722007007", src: FoodImg, },
              { name: "Siddhivinayak Pedewale", Type: "Bakery", MobileNO: "8469963509", src: FoodImg, },
              { name: "Mangalmurti Misal", Type: "Hotel", MobileNO: "9096576230/9503092771", src: FoodImg, },
              { name: "Latkar Pedhewale", Type: "Bakery", MobileNO: "9767857447", src: FoodImg, },
              { name: "Martand Hotel", Type: "Hotel", MobileNO: "9890635540", src: FoodImg, },
              { name: "Hotel Nisarg", Type: "Hotel", MobileNO: "8390494242", src: FoodImg, },
              { name: "Hotel Mejwani", Type: "Hotel", MobileNO: "8380918380", src: FoodImg, },
              { name: "Satari Kandi Pedhe", Type: "Bakery", MobileNO: "9975694495", src: FoodImg, },
              { name: "Amar Pedewale", Type: "Bakery", MobileNO: "76075658595", src: FoodImg, },
              { name: "Mama Pedewale", Type: "Bakery", MobileNO: "7620794973", src: FoodImg, },
              { name: "Sadguru hotel", Type: "Hotel", MobileNO: "8698395917", src: FoodImg, },
              { name: "Hotel Sarang Pure Veg", Type: "Hotel", MobileNO: "2162296295", src: FoodImg, },
              { name: "Hotel Matan Bhakari", Type: "Hotel", MobileNO: "7758928339", src: FoodImg, },
              { name: "Hotel Pushkraj", Type: "Hotel", MobileNO: "9096557558", src: FoodImg, },
              { name: "Malbar", Type: "Hotel", MobileNO: "9545255230", src: FoodImg, },
              { name: "Sai Dhaba", Type: "Hotel", MobileNO: "8625063522", src: FoodImg, },
              { name: "Keshar Dhaba", Type: "Hotel", MobileNO: "7709901487", src: FoodImg, },
              { name: "Hotel Swaraj", Type: "Hotel", MobileNO: "920662131", src: FoodImg, },
              { name: "Hotel janseva", Type: "Hotel", MobileNO: "9890111199", src: FoodImg, },
              { name: "Royal Chainese", Type: "Hotel", MobileNO: "9975009997", src: FoodImg, },
              { name: "yogiz Chainese", Type: "Hotel", MobileNO: "9270020100", src: FoodImg, },
              { name: "Hotel Rassam kadhai", Type: "Hotel", MobileNO: "88882372671/7798731314", src: FoodImg, },
              { name: "Hotel Smile", Type: "Hotel", MobileNO: "9511801881", src: FoodImg, },
              { name: "Hotel lokruchi", Type: "Hotel", MobileNO: "9561339270", src: FoodImg, },
              { name: "Hotel  Maharaja", Type: "Hotel", MobileNO: "9423033667/9423036367", src: FoodImg, },
              { name: "Vasant Bhojnalay", Type: "Hotel", MobileNO: "2162239940", src: FoodImg, },
              { name: "Hotel Gokarn", Type: "Hotel", MobileNO: "8390791316", src: FoodImg, },
              { name: "Hotel Tulja ", Type: "Hotel", MobileNO: "7219222052", src: FoodImg, },
              { name: "Sukhdev ketars", Type: "Hotel", MobileNO: "2162233316", src: FoodImg, },
              { name: "Chilija Chainese", Type: "Hotel", MobileNO: "9561299299", src: FoodImg, },
              { name: "Mithai Kendra", Type: "Hotel", MobileNO: "9970638398", src: FoodImg, },
              { name: "Raviraj Kichen", Type: "Hotel", MobileNO: "9823626108", src: FoodImg, },
              { name: "Ashok Modi Special Kandipedewale", Type: "Bakery", MobileNO: "9822116116", src: FoodImg, },
              { name: "Latkar Bandu sweet mart", Type: "Hotel", MobileNO: "9922908902", src: FoodImg, },
              { name: "All India Bhel Rajwada", Type: "Hotel", MobileNO: "9172401280", src: FoodImg, }, 
               { name: "Nilam Masala and Dryfruites", Type: "Hotel", MobileNO: "9762454590", src: FoodImg, },
               { name: "Hotel Manasi Royal", Type: "Hotel", MobileNO: "744776005", src: FoodImg, },
               { name: "Hotel Shani", Type: "Hotel", MobileNO: "9881075558", src: FoodImg, },
               { name: "TD Misal House", Type: "Hotel", MobileNO: "9401412029", src: FoodImg, },
               { name: "Cool Gola", Type: "Bakery", MobileNO: "9773398861", src: FoodImg, },
               { name: "Kohinoor Biryani", Type: "Hotel", MobileNO: "7875781060", src: FoodImg, },
               { name: "Bakies Baker And Café", Type: "Bakery", MobileNO: "9096450598", src: FoodImg, },
               { name: "Shivraj Dhaba", Type: "Hotel", MobileNO: "8530667007", src: FoodImg, },
               { name: "Sahyadri Hotel", Type: "Bakery", MobileNO: "9923546525", src: FoodImg, },
               { name: "Bore Ketras", Type: "Bakery", MobileNO: "8668858480", src: FoodImg, },
               { name: "Kulkarni Ketras", Type: "Bakery", MobileNO: "9172894951", src: FoodImg, },
               { name: "Relax Internet Café", Type: "Bakery", MobileNO: "8421874341", src: FoodImg, },
               { name: "Nadbrham Idali", Type: "Bakery", MobileNO: "706668448", src: FoodImg, },
               { name: "Hotel Rutu Pure Veg", Type: "Bakery", MobileNO: "8999408086", src: FoodImg, },
               { name: "Hotel Lucky", Type: "Bakery", MobileNO: "8469539639", src: FoodImg, },
               { name: "Aditi Foods and Products", Type: "Bakery", MobileNO: "8788689489", src: FoodImg, },
               { name: "Kagaj Icecream Parlour", Type: "Bakery", MobileNO: "9923948118", src: FoodImg, },
               { name: "Test Ride Foods (Cake)", Type: "Bakery", MobileNO: "9881113689", src: FoodImg, },
               { name: "And Misal House", Type: "Hotel", MobileNO: "9623876267", src: FoodImg, },
               { name: "Shri Datt Bhojnalay", Type: "Hotel", MobileNO: "8805984329", src: FoodImg, },
               { name: "Cake Express", Type: "Bakery", MobileNO: "7387255710", src: FoodImg, },
               { name: "Lakshman Kirana Store", Type: "Hotel", MobileNO: "9881346947", src: FoodImg, },
               { name: "Pragati Bhaji And Friut Center", Type: "Hotel", MobileNO: "9922455677", src: FoodImg, },
               { name: "Bhoiraj Dryfish", Type: "Hotel", MobileNO: "9604489182", src: FoodImg, },
               { name: "Hotel Lucky", Type: "Hotel", MobileNO: "9922580600", src: FoodImg, },
               { name: "Shriram Bakers", Type: "Bakery", MobileNO: "9860103361", src: FoodImg, },
               { name: "Chicken Vicken", Type: "Hotel", MobileNO: "8983819702", src: FoodImg, },
               { name: "Parvej Chicken Center", Type: "Hotel", MobileNO: "9960559225", src: FoodImg, },
               { name: "New Chetna Hotel", Type: "Hotel", MobileNO: "9970159135", src: FoodImg, },
               { name: "Power Zone Batters", Type: "Hotel", MobileNO: "9890146999", src: FoodImg, },
               { name: "Appa Cahavale Snakes Center", Type: "Hotel", MobileNO: "7218617792", src: FoodImg, },
               { name: "Hotel Malberi", Type: "Hotel", MobileNO: "8888867264", src: FoodImg, },
               { name: "Cool Point", Type: "Hotel", MobileNO: "9763213995", src: FoodImg, },
               { name: "Dhoke Amruttulya", Type: "Hotel", MobileNO: "7083043484", src: FoodImg, },
               { name: "Khandoli Hotel", Type: "Hotel", MobileNO: "9552085723", src: FoodImg, },
               { name: "mohitej bake shop", Type: "Bakery", MobileNO: "9881588031", src: FoodImg1, },
               { name: "aarmar bakery ", Type: "Bakery", MobileNO: "7933519262", src: FoodImg1, }


      ]

  return (
    <>

      <Container fluid className=''>

        <Row className='headingimg p-4'>
          <Col className='m-4'>
            <h1 className='text-white m-5 px-2 fw-bold text-start'>Food</h1>
            <div className='text-white m-5 fw-bold text-start'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Food</a></div>
          </Col>
        </Row>

        <Row className='p-3'>
          {imgData.map((item, index) => {
            return (<Col key={index} sm={6} lg={3} className='p-3'>

              <div className="card p-4">
                <img src={item.src} className='img-fluid img-circle' alt="..." />
                <div className="card-body text-center">
                  <h5 className="card-title text-center">{item.name}</h5>
                  <h6>{item.Type}</h6>

                  <p>{item.MobileNO}</p>

                  <a href="#" className="btn btn-primary text-center">View More</a>
                </div>
              </div>
            </Col>)
          })}


        </Row>

        {/* <Row>
        <h1 className='text-dark p-3 fw-bold text-center'>Satara Food</h1>

          <Col className='p-3  textJustify'>

            <div className="card " >
              <div className="row g-0">
                <div className="col-md-4 p-0">
                  <img src={Food1} className="img-fluid rounded" alt="..." />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title">Kandi Pedhe</h5>
                    <p className="card-text">Satara special Kandi Pedha is a very famous dish.</p>
                    <a href="./KandiPedhe"  className="btn btn-primary text-center">View More</a>

                  </div>
                </div>
              </div>
            </div>

          </Col>

          <Col className='p-3  textJustify'>

            <div className="card" >
              <div className="row g-0">
                <div className="col-md-4">
                  <img src={Food2} className="img-fluid rounded" alt="..." />

                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title">Vada Pav</h5>
                    <p className="card-text">Satara special Vada Pav is a very famous dish.</p>
                    <a href="./VadaPav" className="btn btn-primary text-center">View More</a>

                  </div>
                </div>
              </div>
            </div>

          </Col>
          <Col className='p-3  textJustify'>

            <div className="card" >
              <div className="row g-0">
                <div className="col-md-4">
                  <img src={Food3} className="img-fluid rounded" alt="..." />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title">Dabeli</h5>
                    <p className="card-text">Satara special Dabeli is a very famous dish.</p>
                    <a href="./Dabeli" className="btn btn-primary text-center">View More</a>

                  </div>
                </div>
              </div>
            </div>

          </Col>
        </Row>
        <Row>

<Col className='p-3  textJustify'>

  <div className="card " >
    <div className="row g-0">
      <div className="col-md-4 p-0">
        <img src={Food4} className="img-fluid rounded" alt="..." />
      </div>
      <div className="col-md-8">
        <div className="card-body">
          <h5 className="card-title">Veg Thali</h5>
          <p className="card-text">Satara special Veg Thali is a very famous dish.</p>
          <a href="./VegThali" className="btn btn-primary text-center">View More</a>

        </div>
      </div>
    </div>
  </div>

</Col>

<Col className='p-3  textJustify'>

  <div className="card" >
    <div className="row g-0">
      <div className="col-md-4">
        <img src={Food5} className="img-fluid rounded" alt="..." />
      </div>
      <div className="col-md-8">
        <div className="card-body">
          <h5 className="card-title">Biryani</h5>
          <p className="card-text">Satara special Biryani is very special dish.</p>
          <a href="./Biryani" className="btn btn-primary text-center">View More</a>

        </div>
      </div>
    </div>
  </div>

</Col>
<Col className='p-3  textJustify'>

  <div className="card" >
    <div className="row g-0">
      <div className="col-md-4">
        <img src={Food6} className="img-fluid rounded" alt="..." />
      </div>
      <div className="col-md-8">
        <div className="card-body">
          <h5 className="card-title">Panipuri</h5>
          <p className="card-text">Satara special Panipuri is a very famous dish.</p>
          <a href="./Panipuri" className="btn btn-primary text-center">View More</a>

        </div>
      </div>
    </div>
  </div>

</Col>
</Row> */}

      </ Container >



    </>
  )

}

export default Food;