import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import React from 'react';
function Stationary() {
    return(
<>
            <Container fluid className=''>

                <Row className='headingimg p-4'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-start'>Stationary</h1>
                        <div className='text-white m-5 fw-bold text-start'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Stationary</a></div>
                    </Col>

                </Row>
                <Row>
                    
                    <Col className='p-3  textJustify'>
                        <p >
                        Stationary Page                     </p>
                      
                    </Col>


                </Row>
             
            </ Container >



        </>    
    )
    
    }
    
    export default Stationary;