import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from './Pages/Layout';
import Home from "./Pages/Home";
import NoPage from "./Pages/NoPage";
import About from './Pages/About';
import Contact from './Pages/Contact';
import Health from './Pages/Health';
import Food from './Pages/Food';
import Travel from './Pages/Travel';
import Electronics from './Pages/Electronics';
import Sports from './Pages/Sports';
import Furniture from './Pages/Furniture';
import Stationary from './Pages/Stationary';
import KandiPedhe from './Pages/KandiPedhe';
import VadaPav from './Pages/VadaPav';
import Dabeli from './Pages/Dabeli';
import VegThali from './Pages/VegThali';
import Panipuri from './Pages/Panipuri';
import Biryani from './Pages/Biryani';
import Hotel from './Pages/Hotel';
import Sayaji from './Pages/Sayaji';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/health" element={<Health />} />
          <Route path="/food" element={<Food />} />
          <Route path="/travel" element={<Travel />} />
          <Route path="/sports" element={<Sports />} />
          <Route path="/stationary" element={<Stationary />} />
          <Route path="/electronics" element={<Electronics />} />
          <Route path="/furniture" element={<Furniture />} />
          <Route path="/kandipedhe" element={<KandiPedhe />} />
          <Route path="/vadapav" element={<VadaPav />} />
          <Route path="/dabeli" element={<Dabeli />} />
          <Route path="/vegthali" element={<VegThali />} />
          <Route path="/panipuri" element={<Panipuri />} />
          <Route path="/biryani" element={<Biryani />} />
          <Route path="/hotel" element={<Hotel />} />
          <Route path="/sayaji" element={<Sayaji />} />

          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
